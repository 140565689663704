.login-wrap{
	width:100%;
	margin:auto;
	max-width:525px;
	min-height:670px;
	position:relative;
	// background:url(https://raw.githubusercontent.com/khadkamhn/day-01-login-form/master/img/bg.jpg) no-repeat center;
	box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}

.login-html{
	width:100%;
	height:100%;
	position:absolute;
	padding:90px 70px 50px 70px;
    background:rgba($color: #222, $alpha: .9);
    .sign-in-htm,
    .sign-up-htm{
        top:0;
        left:0;
        right:0;
        bottom:0;
        position:absolute;
        transition:all .4s linear;
    }

    .tab {
        text-transform:uppercase;
        color:#fff;
    }
    .tab{
        font-size:22px;
        margin-right:15px;
        padding-bottom:5px;
        margin:0 15px 10px 0;
        display:inline-block;
        border-bottom:2px solid var(--primary-color);
        color:#fff;
    }
}
.login-form{
	min-height:345px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
    .group{
        margin-bottom:15px;
        #signin-check{
            display:none;
        }
        .label, .button{
            text-transform:uppercase;
        }
        .input,
        .button{
            width:100%;
            color:#fff;
            display:block;
            border:none;
            padding:15px 20px;
            border-radius:25px;
            background:rgba(255,255,255,.1);
        }
        input[data-type="password"]{
            text-security:circle;
            -webkit-text-security:circle;
        }
        .label{
            color:#aaa;
            font-size:12px;
        }
        .button{
            background-color: var(--primary-color);
        }
        label {
            .icon{
                width:15px;
                height:15px;
                border-radius:2px;
                position:relative;
                display:inline-block;
                background:rgba(255,255,255,.1);
            }
            .icon:before, .icon:after{
                content:'';
                width:10px;
                height:2px;
                background:#fff;
                position:absolute;
                transition:all .2s ease-in-out 0s;
            }
            .icon:before{
                left:3px;
                width:5px;
                bottom:6px;
                transform:scale(0) rotate(0);
            }
            .icon:after{
                top:6px;
                right:0;
                transform:scale(0) rotate(0);
            }

        }
        #signin-check:checked + label{
            color:#fff;
        }
        #signin-check:checked + label .icon{
            background:white;
        }
    }
}

.hr{
	height:2px;
	margin:30px 0 30px 0;
	background:rgba(255,255,255,.2);
}
.foot-lnk{
    text-align:center;
    a {
        color: #aaa;
    }
}
.google-login-button{
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: white;
    height: 50px;
    margin: 10px auto;
}
.facebook-login-button{
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: #4c69ba;
    color: white;
    height: 50px;
    margin: 10px auto;
}
.login-button {
    width: 100px;
}