.hide-info-window{
    display: none;
}
.share-map-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    .share-map-button {
        width: 100%;
        // background-color: rgba($color: #fff, $alpha: 1) !important;
    }
}
.map-container{
    height: 100%;
    width: 100%;
    transition: none;
    @media not all and (min-resolution:.001dpcm) { 
        height: calc(100vh - 200px);
    }
}