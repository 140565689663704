#other-group-list {
    width: 100%;
    color: white;
    height: 100%;
    position: relative;
    * {
        transition: none;
    }
    .input-wrapper {
        width: calc(100% - 20px);
        margin: 10px;
        min-height: 50px;
        vertical-align: top;
        border: none;
        border-bottom: solid 1px white;
        border-radius: 0;
        background:linear-gradient(to right,  rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0) 100%);
    }
    input{
        background: none;
        border: none;
        box-shadow: none;
        width: calc(100% - 65px);
        height: 40px;
        margin: 0;
    }
    .title-wrapper{
        display:flex;
        flex-direction: row;
        width: calc(100% - 40px);
        justify-content: space-between;
        align-items: center;
        margin: 20px;
        .button{
            // border: 0.5px solid var(--mdc-theme-primary);
            height: 40px;
            width: 180px;
            font-size: 12px;
            padding: 0;
            line-height: 20px;
            vertical-align: middle;
            font-weight: 600;
        }
        .title{
            min-height: 50px;
            vertical-align: top;
            line-height: 50px;
            border-radius: 10px;
            color: var(--mdc-theme-primary);
            font-size: 1.2rem;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    .list-wrapper{
        position: related;
        padding: 10px;
        width: 100%;
        // float: right;
        // justify-content: space-between;
        // align-items: flex-start;
        // flex-wrap: wrap;
        .city-title{
            padding: 10px 0 0 10px;
            width: 100%;
            border-bottom: 0.5px var(--mdc-theme-primary) solid;
            color: var(--mdc-theme-primary);
            font-size: 10px;
        }
    }
    .group-content{
            transition: all ease-in 0.25s;
            box-shadow: none;
            border: none;
            background: transparent;
            outline: inherit;
            font: inherit;
            text-align: left;
            min-height: 80px;
            padding: 20px;
            vertical-align: top;
            width: 100%;
            color:white;
            // border-left: 1px var(--mdc-theme-primary) solid;
            padding-left: 25px;
            
            // background:rgba(0, 0, 0, 0.3);
            &:hover{
                background:rgba(255,255,255, 0.1);
            }
        .group-title{
            //    color:var(--mdc-theme-primary);
            
            text-transform: uppercase;
            line-height: 1;
            font-size: 1rem;
        }
        .group-intro{
            // color:var(--mdc-theme-primary);
            font-weight: normal;
            font-size: 12px;
        }
        &.button{
            cursor: pointer;
            font-weight: bold;
        }
    }
    .search-button{
        color: white;
        vertical-align: middle;
        float: right;
        // padding: 10px;
        cursor: pointer;
    }
    
    // @media screen and (max-width: 960px) {
    //     .button{
    //         width: 100%;
    //     }
    // }
}