.gm-style-iw-c{
    border-radius: 0 !important;
    max-width: 70vw !important;
    max-height: 600px;
    background-color: rgba($color: #ffffff, $alpha: 0.9) !important;
}
.gm-style-iw .gm-style-iw-c{
    border-radius: 0;
}
.infowindow{
    .photo{
        width: 300px;
        height: 150px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        @media screen and (max-width: 670px) {
            width: 100%;
        }
    }
}

@media screen and (max-width: 670px) {
    .infowindow{
        .photo{
            width: 100%;
        }
    }
    .gm-style-iw-c{
        border-radius: 0 !important;
        width: 90vw !important;
        max-height: 600px;
        background-color: rgba($color: #ffffff, $alpha: 0.9) !important;
    }
}