/* Navbar */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 2rem;
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 0;
    left: 0;
    // box-shadow: 0px 5px 5px black;
    opacity: 0.9;
    vertical-align: middle;
    // background-color: rgba($color: black, $alpha: 0.9);
    .nav-auth {
      .li ,a {
        font-size: 2rem;
      }
    }
    ul {
        display: flex;
    }
    .li ,a {
      color: #fff;
      padding-right: 0.45rem;
      margin: 0 1rem;
    }
    li:hover, a {
      transition: all 0.5s !important;
      cursor: pointer;
    }
    .welcome span {
      margin-right: 1rem;
    }
    .logo {
      background-image: url('../../img/logo.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 40px;
      height: 50px;
      border: none;
      margin: 0;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
    }
    a:hover{
      cursor: pointer;
    }
    li::after{
      content:'';
      opacity: 0;
      position: absolute;
      top: 50px;
      right: 24px;
      font-size: 1rem;
      width: 30px;
      transition: all 0.5s;
      color: rgba($color: #ffffff, $alpha: 0.2)
    }
    li{
      position: relative;
    }
    li:hover::after{
      opacity: 1;
    }
    .logout:after{
      content: 'LOGOUT'
    }
    .account:after{
      content: 'ACCOUNT';
      right: 47px;
    }
    .map:after{
      content:'MAP';
      right: 20px;
    }
    @media screen and (max-width: 450px) {
      ul{
        // flex-grow: 1;
        margin: 0;
        padding: 0;
      }
      li, a{
        margin: 5px;
        padding: 0;
      }
    }
}