.title-animation {
        *,
        *::before,
        *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    /* block-$ */
    .block-effect {
        font-size: calc(8px + 6vw);
    }

    .block-reveal {
        --t: calc(var(--td) + var(--d));

        color: transparent;
        padding: 4px;

        position: relative;
        overflow: hidden;

        animation: revealBlock 0s var(--t) forwards;
    }

    .block-reveal::after {
        content: '';

        width: 0%;
        height: 100%;
        padding-bottom: 4px;

        position: absolute;
        top: 0;
        left: 0;

        background: var(--bc);
        animation: revealingIn var(--td) var(--d) forwards, revealingOut var(--td) var(--t) forwards;
    }


    /* animations */
    @keyframes revealBlock {
        100% {
            color: #fff;
        }
    }

    @keyframes revealingIn {
        0% {
            width: 0;
        }
        100% {
            width: 100%;
        }
    }

    @keyframes revealingOut {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(100%);
        }
    }
}