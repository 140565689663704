@import "./_variable.scss";
/* Global Styles */
:root {
  --primary-color: #0f2540;
  --dark-color: black;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --mdc-theme-primary: #FB667A;
  --nav-height: 80px;
  --footer-height: 50px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // transition: all 1s;
}

body {
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
  -webkit-tap-highlight-color: transparent;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

/* GLOBAL STYLE, DO NOT DELETE IT */
.button{
  color: var(--mdc-theme-primary);
}
.mdc-button__label{
  font-weight: bold;
}
.input-wrapper{
  border: .5px solid var(--mdc-theme-primary);
  border-radius: 10px;
  padding-left: 15px;
  height: 40px;
  input, textarea{
      color: white;
      background: none;
      border: none;
      box-shadow: none;
      width: 100%;
      height: 100%;
      margin: 0;
      outline: none;
      font-size: 1rem;
  }
  .label {
    color: var(--mdc-theme-primary);
    font-size: 12px;
    line-height: 0 !important;
    transform: translateY(-10px);
  }
}

/* Utilities */

.container {
  position: relative;
  background: rgb(251,121,187);
  background: linear-gradient(to bottom,  rgba(251,121,187,1) 0, rgba(93,255,250,1) 100%);
  width: 100%;
  margin: auto;
  padding: 0 2rem;
  padding-top: $nav-height;
  min-height: 100vh;
  @media screen and (max-width: 760px) {
    padding: $nav-height 10px 0 10px;
  }
}

#three-container {
  position: fixed;
  top:0;
  left: 0;
  // z-index: -2;
  height: 100vh;
  width: 100vw;
}

.full-container {
  width: 100%;
  height: 100%;
}

footer{
  position: relative;
  color: white;
  text-align: center;
  width: 100%;
  height: $footer-height;
  z-index: 10;
}

/* Posts Page */
.post-form .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.post {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 2rem;
  align-items: center;
}

.post > div:first-child {
  text-align: center;
}

.post img {
  width: 100px;
}

.post .comment-count {
  background: var(--light-color);
  color: var(--primary-color);
  padding: 0.1rem 0.2rem;
  border-radius: 5px;
  font-size: 0.8rem;
}

.post .post-date {
  color: #aaa;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

/* Alert */
.alert-contianer {
  top: 30px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 10;
}
.alert {
  height: 60px;
  line-height: 60px;
  color: white;
  text-align: center;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
}
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}
.alert-light {
  background: var(--light-color);
  color: #333;
}
.alert-dark {
  color: var(--dark-color);
}
.alert-danger {
  color: var(--danger-color);
}
.alert-success {
  color: var(--success-color);
}


/* Animation */
.fade-in {
  -webkit-animation: fadein 1s ; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s ease-in-out; /* Firefox < 16 */
  -ms-animation: fadein 1s ease-in-out; /* Internet Explorer */
  -o-animation: fadein 1s ease-in-out; /* Opera < 12.1 */
  animation: fadein 1s ease-in-out;
}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}