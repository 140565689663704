.mdc-dialog__container{
    width: 800px;
    height: 80vh;
    .mdc-dialog__surface{
        width: 100%;
        height: 100%;
    }
    .add-location-modal-content{
        width: 100%;
        height: 100%;
    }
    @media screen and (max-width: 870px) {
        width: 100%;
        height: 90%;
    }
}
