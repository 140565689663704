#selected-group-list {
    width: 100%;
    color: white;
    height: 100%;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    * {
        transition: none;
    }
    .list-wrapper{
        width: 350px;
        position: relative;
        width: calc(100% + 15px);
        height: 100%;
        overflow-y: scroll;
    }
    .title{
        // background: #0000002d;
        margin: 20px;
        min-height: 50px;
        vertical-align: top;
        line-height: 50px;
        padding-left: 10px;
        border-radius: 10px;
        color: var(--mdc-theme-primary);
        font-size: 1.2rem;
        font-weight: bold;
        // border: .5px solid var(--mdc-theme-primary);
        float: right;
        width: calc(100% - 35px);
        text-transform: uppercase;
    }
    .group-content{
        transition: all ease-in 0.25s;
        box-shadow: none;
        border: none;
        outline: inherit;
        font: inherit;
        text-align: left;
        background: transparent;
        padding: 10px;
        min-height: 50px;
        vertical-align: top;
        color: white;
        width: calc(100% - 50px);
        float: right;
        text-transform: uppercase;
        line-height: 1;
        font-size: 1rem;
        border-radius: 10px;
        &.button{
            cursor: pointer;
            font-weight: bold;
            // margin-right: '100px';
        }
        &:hover{
            background:rgba(255,255,255, 0.1);
        }
    }
    .icon-button {
        height: 40px;
        width: 40px;
        border: .5px solid rgba(255, 255, 255, 0.5);
        vertical-align: middle;
        text-align: center;
        border-radius: 20px;
        margin: 10px;
        line-height: 40px;
        font-size: 20px;
        float: right;
        color: white;
        &.wrapper{
            border: none;
            margin: none;
            cursor: pointer;
        }
    }
    @media screen and (max-width: 960px) {
        .title{
            display: none;
        }
        .list-wrapper{
            margin-top: 40px;
            min-height: calc(100vh - 220px);
        }
        .group-content{
            height: 50px;
        }
    }
}