/* Landing Page */

.landing-inner {
  color: #fff;
  height: calc(100vh - 150px);
  width: 80%;
  overflow: hidden;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  pointer-events: none;
}
.bubble-container {
  position: fixed;
  right: 30%;
  pointer-events: auto;
  @media screen and (max-width: 760px) {
    right: 10%;
  }
  top: 250px;
  cursor: pointer;
  .bubble {
    background-color: #ffffff;
    background-image: -webkit-linear-gradient('to bottom', hsla(0, 0%, 100%, .5), hsla(0, 0%, 100%, 0));
    background-image: -moz-linear-gradient('to bottom', hsla(0, 0%, 100%, .5), hsla(0, 0%, 100%, 0));
    background-image: -ms-linear-gradient('to bottom', hsla(0, 0%, 100%, .5), hsla(0, 0%, 100%, 0));
    background-image: -o-linear-gradient('to bottom', hsla(0, 0%, 100%, .5), hsla(0, 0%, 100%, 0));
    background-image: linear-gradient('to bottom', hsla(0, 0%, 100%, .5), hsla(0, 0%, 100%, 0));
    border-radius: 5px;
    box-shadow: inset 0 1px 1px hsla(0, 0%, 100%, .5), 3px 3px 0 hsla(0, 0%, 0%, .1);
    display: inline-block;
    font-weight: 700;
    font-size: 1.5em;
    color: black;
    padding: 15px 25px;
    position: relative;
    text-shadow: 0 1px 1px hsla(0, 0%, 100%, .5);
    opacity: 0;
  }
  
  .bubble:after,
  .bubble:before {
    border-bottom: 20px solid transparent;
    border-left: 20px solid #ffffff;
    bottom: -20px;
    content: '';
    position: absolute;
    left: 25px;
  }
  
  .bubble:before {
    border-left: 20px solid hsla(0, 0%, 0%, .1);
    bottom: -20px;
    left: 22px;
  }
}