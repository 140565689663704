#group-detail{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .header{
        width:calc(100% - 15px);
        float: right;
        padding: 20px;
    }
    .title {
        color: var(--mdc-theme-primary);
        font-weight: bold;
        text-transform: uppercase;
        width: 100%;
        font-size: 1.2rem;
        margin: 20px 0;
        padding: 10px;
        border-bottom: solid var(--mdc-theme-primary) 1px;
        p{
            text-transform: none;
            color: white;
            font-size: 12px;
        }
        
    }
    .icon-button {
        height: 40px;
        width: 40px;
        border: .5px solid rgba(255, 255, 255, 0.5);
        vertical-align: middle;
        text-align: center;
        border-radius: 20px;
        line-height: 40px;
        font-size: 20px;
        color: white;
        cursor: pointer;
        &.favorite{
            color: var(--mdc-theme-primary);
            float: right;
            border: none;
            font-size: 2.5rem;
        }
        &.edit{
            vertical-align: middle;
            line-height: 1.5rem;
            color: var(--mdc-theme-primary);
            border: none;
            font-size: 1.5rem;
            float: right;
        }
    }
    .group-wrapper{
        width: calc(100% + 15px);
        overflow-y: scroll;
        flex-wrap: wrap;
        color: white;
    }
    @media screen and(max-width: 870px) {
        width: 100%;
        height: 100%;
    }
}