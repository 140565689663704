#profile-modal{
    
    // height: 100%;
    height: calc(100vh - 20px);
    margin: 10px;
    width: 500px;
    position: fixed;
    right: -500px;
    top: 0;
    opacity: 0;
    z-index: 3;
    box-shadow: 0 0 15px 2px rgba(0,0,0,0.1);
    // background:  linear-gradient(to top, #dd879fe5 0%,#ffffffe8 100%);
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
    @media screen and (max-width: 500px) {
        width: calc(100% - 20px);
    }
    
    .header {
        border-bottom: 1px solid rgb(199, 199, 199);
        align-items: center;
        justify-content: space-between;
        align-content: stretch;
        display: flex;
        .close-button{
            float: left;
            cursor: pointer;
            margin: 10px;
            font-size: 2em;
            font-weight: bold;
            color: var(--mdc-theme-primary);
            width: 30%;
        }
        .header-title{
            color: var(--mdc-theme-primary);
            font-weight: bold;
            text-align: center;
            width: 30%;
            font-size: 1.2em;
        }
        .right-section{
            width: 30%;
        }
    }
    .profile-table{
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: auto;
    }
    .profile-container{
        border-bottom: 1px solid rgba(199, 199, 199, 0.3);
        display: flex;
        height: 60px;
        align-items: flex-end;
    }
    .profile-title{
        display: inline-block;
        width :100px;
        font-size: 1rem;
        color: var(--mdc-theme-primary);
        padding-left: 20px;
    }
    .profile-content{
        font-weight: 300;
        font-size: 1rem;
        display: inline-block;
        flex-grow: 1;
        padding-left: 20px;
    }
}