.location-card {
    * {
        transition: none;
    }
    &.group-content{
        margin: 10px;
        border-bottom: .5px solid rgba(255, 255, 255, 0.5);
        color: white;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        font-weight: 300;
        opacity: 0;
        width: calc(100% - 30px);
        &.button{
            cursor: pointer;
            // margin-right: '100px';
        }
        .right-section{
            line-height: 1.5rem;
            vertical-align: top;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .edit-button{
            color: white;
            vertical-align: middle;
            float: right;
            padding: 10px;
            cursor: pointer;
        }
        .location-title{
            font-weight: 700;
        }
        .location-addr{
            font-size: 0.8rem;
        }
        .location-img{
            width: 300px;
            margin-right: 10px;
        }
        &.location-clickable{
            cursor: pointer;
            &:hover{
                background-color: rgba($color: #ffffff, $alpha: 0.2);
            }
            transition: all 0.5s;
        }
        @media screen and (max-width: 1150px) {
            flex-direction: column;
            .location-img{
                margin-right: 0;
                width: 100%;
            }
        }
    }
}