#dashboard{
    display: flex;
    flex-wrap: wrap;
    z-index: 2;
    width: 100%;
    >.left-section{
        width: 400px;
        height: calc(100vh - 130px);
        position: relative;
    }
    >.right-section{
        position: relative;
        flex-grow: 1;
        min-height: calc(100vh - 130px);
        width: 50%;
        .map-section-wrapper{
            width: 100%;
            height: calc(100vh - 130px);
            display: flex;
            flex-direction: column;
        }
    }
    @media screen and (max-width: 670px) {
        >.left-section{
            width: 100%;
            height: initial;
            max-height: 60vh;
            min-height: 100px;
        }
        >.right-section{
            width: 100%;
            height: initial;
        }
        
    }
}