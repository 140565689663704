#edit-group-modal{
    .mdc-select{
        width: calc(50% - 10px);
        margin: 10px;
        margin-right: 0;
        vertical-align: top;
    }
    .city-input{
        width: calc(50% - 10px); 
        margin: 10px;
        margin-left:0;
    }
}